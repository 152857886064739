import { ExperimentsConsts } from '../../../consts/experiments';
import { ServerErrorType } from '../../../types/errors';
import { FormSelectedSlot } from '../../../types/formSelectedSlots';
import { getServiceSlotIdentifier } from '../../../utils';
import { OnError } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import {
  queryServiceOptionsAndVariants,
  getServiceOptionsAndVariantsByServiceId,
} from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/http';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';

export type GetOptionsAndVariantsDataArgs = {
  formSelectedSlot: FormSelectedSlot;
  onError?: OnError;
};
export const getOptionsAndVariantsData = async ({
  formSelectedSlot,
  httpClient,
  experiments,
  reportError,
}: GetOptionsAndVariantsDataArgs & FormApiContext): Promise<{
  [key: string]: ServiceOptionsAndVariants | undefined;
}> => {
  const isQueryServiceOptionsAndVariantsEnabled = experiments.enabled(
    ExperimentsConsts.queryServiceOptionsAndVariants,
  );

  if (!isQueryServiceOptionsAndVariantsEnabled) {
    return getOptionsAndVariantsDataOld({
      formSelectedSlot,
      reportError,
      httpClient,
    });
  }

  const serviceIds = formSelectedSlot.nestedSlots.map((slot) => slot.serviceId);
  const { data: serviceOptionsAndVariantsList } = await withErrorBoundary(
    {
      fn: async () => {
        const { data: response } = await httpClient.request(
          queryServiceOptionsAndVariants({
            query: {
              filter: {
                serviceId: {
                  $in: serviceIds,
                },
              },
            },
          }),
        );

        return response?.serviceOptionsAndVariantsList;
      },
      mapError: (e) => ({
        error: ServerErrorType.OPTIONS_AND_VARIANTS_FAILED,
        shouldReport: true,
      }),
      fallback: undefined,
    },
    reportError,
  );

  return formSelectedSlot.nestedSlots?.reduce((acc, slot) => {
    acc[getServiceSlotIdentifier(slot)] = serviceOptionsAndVariantsList?.find(
      (variant) => variant.serviceId === slot.serviceId,
    );
    return acc;
  }, {} as { [key: string]: ServiceOptionsAndVariants | undefined });
};

export const getOptionsAndVariantsDataOld = async ({
  formSelectedSlot,
  httpClient,
  reportError,
}: {
  httpClient: IHttpClient;
  reportError: (error: string | Error) => string | undefined;
  formSelectedSlot: FormSelectedSlot;
  onError?: OnError;
}): Promise<{ [key: string]: ServiceOptionsAndVariants | undefined }> => {
  const res = await Promise.all(
    formSelectedSlot.nestedSlots.map(async (slot) => {
      const { data } = await withErrorBoundary(
        {
          fn: async () => {
            const { data: response } = await httpClient.request(
              getServiceOptionsAndVariantsByServiceId({
                serviceId: slot.serviceId,
              }),
            );

            return response?.serviceVariants;
          },
          mapError: (e) => ({
            error: ServerErrorType.OPTIONS_AND_VARIANTS_FAILED,
            shouldReport: true,
          }),
          fallback: undefined,
        },
        reportError,
      );

      return {
        slot,
        data,
      };
    }),
  );

  return res.reduce((acc, curr) => {
    acc[getServiceSlotIdentifier(curr.slot)] = curr.data;
    return acc;
  }, {} as any);
};
