import { ServerErrorType } from '../../../types/errors';
import { FormSelectedSlot } from '../../../types/formSelectedSlots';
import { getServiceSlotIdentifier } from '../../../utils';
import { OnError } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import { ListEligibleMembershipsResponse } from '@wix/ambassador-memberships-spi-host/http';
import { BOOKINGS_APP_DEF_ID } from '@wix/bookings-adapter-ooi-wix-sdk';

export type ListMembershipsArgs = {
  formSelectedSlot: FormSelectedSlot;
  onError?: OnError;
};

export const listMemberships = async ({
  membershipsServer,
  reportError,
  authorization,
  formSelectedSlot,
  onError,
}: ListMembershipsArgs & FormApiContext): Promise<{
  [key: string]: ListEligibleMembershipsResponse;
}> => {
  const lineItems = formSelectedSlot.nestedSlots
    .filter((slot) => slot.startDate)
    .map((slot) => {
      return {
        id: slot.lineItemId,
        rootCatalogItemId: slot.serviceId,
        catalogReference: {
          catalogItemId: slot.serviceId,
          appId: BOOKINGS_APP_DEF_ID,
        },
        serviceProperties: {
          scheduledDate: new Date(slot.startDate),
          numberOfParticipants: 1,
        },
      };
    });

  let membersResponse: {
    data?: ListEligibleMembershipsResponse;
    error?: ServerErrorType;
  } = {};
  if (lineItems.length) {
    membersResponse = await withErrorBoundary(
      {
        fn: () =>
          membershipsServer
            .MembershipsSpiHost()({ Authorization: authorization })
            .listEligibleMemberships({ lineItems }),
        mapError: (e) => {
          console.log('listMemberships error', e);
          return {
            error: ServerErrorType.GENERIC_PRICING_PLAN_ERROR,
            shouldReport: true,
          };
        },
        fallback: {
          eligibleMemberships: [],
        },
      },
      reportError,
    );
  }
  if (membersResponse && membersResponse.error) {
    onError?.(membersResponse.error);
  }

  const result: { [key: string]: ListEligibleMembershipsResponse } = {};

  formSelectedSlot.nestedSlots.forEach((slot) => {
    result[getServiceSlotIdentifier(slot)] = {
      eligibleMemberships:
        membersResponse.data?.eligibleMemberships?.filter((membership) =>
          membership.lineItemIds?.includes(slot.lineItemId),
        ) || [],
      invalidMemberships:
        membersResponse.data?.invalidMemberships?.filter((membership) =>
          membership.membership?.lineItemIds?.includes(slot.lineItemId),
        ) || [],
      selectedMemberships:
        membersResponse.data?.selectedMemberships?.filter((membership) =>
          membership.lineItemIds?.includes(slot.lineItemId),
        ) || [],
    };
  });

  return result;
};
