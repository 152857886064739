import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import type { Experiments } from '@wix/yoshi-flow-editor';

export function createWixSdkAdapter(
  controllerConfig: IWidgetControllerConfig,
  experiments: Experiments,
): WixOOISDKAdapter {
  return new WixOOISDKAdapter(
    controllerConfig.wixCodeApi as any,
    controllerConfig.platformAPIs,
    controllerConfig.appParams,
    controllerConfig.compId,
    experiments,
  );
}
