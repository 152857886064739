import { FormField } from '@wix/ambassador-services-catalog-server/types';
import {
  BookedLocation,
  LocationType,
} from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import {
  FieldOption,
  Operation,
  V2Rule,
  FieldType,
} from '@wix/ambassador-forms-v2-form/types';
import {
  createAddressField,
  createFormField,
  getFieldType,
} from './service-form-field.mapper';
import {
  FormView,
  Submission as FormSubmission,
  SubmissionValue,
} from '@wix/forms-ui/types';

import {
  BookingRequestKeyMappings,
  trimPhoneValue,
} from './form-submission.mapper';
import {
  FieldLayout,
  TFunction,
  ComplexPhone,
  BusinessInfo,
} from '../../types/types';
import { SlotService } from '../state/types';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/build/cjs/types.impl';
import { getServiceSlotIdentifier, mapToArray } from '../index';

export enum ReservedFieldIds {
  SMS_REMINDER = 'sms-reminder-id',
}

export const createFormView = ({
  slotServices,
  businessInfo,
  preFilledValues,
  isFixFormUoUCheckboxLinkEnabled,
  isAlwaysShowComplexPhoneFieldEnabled = true,
  t,
  shouldShowContactFields = true,
  serviceOptionsAndVariants,
}: {
  slotServices: { [key: string]: SlotService };
  serviceOptionsAndVariants?: {
    [key: string]: ServiceOptionsAndVariants | undefined;
  };
  businessInfo: BusinessInfo;
  t: TFunction;
  isFixFormUoUCheckboxLinkEnabled?: boolean;
  preFilledValues?: FormSubmission;
  isAlwaysShowComplexPhoneFieldEnabled?: boolean;
  shouldShowContactFields?: boolean;
}): FormView => {
  const slotServicesArray = mapToArray<SlotService>(slotServices);
  const slotService = slotServicesArray[0];

  const form = slotService!.service.formFromCatalog;
  const { customFields = [] } = slotService!.service.formFromCatalog;
  const maxNumberOfParticipants = slotService!.service.maxNumberOfParticipants;
  const { isVariedPricing } = slotService!.service.payment.paymentDetails;
  const doesServiceOptionsAndVariantsExists =
    slotService &&
    !!serviceOptionsAndVariants?.[
      getServiceSlotIdentifier(slotService.nestedSlot)
    ];
  const isDynamicPrice = doesServiceOptionsAndVariantsExists && isVariedPricing;

  const { isSMSReminderEnabled, countryCode } = businessInfo;

  const shouldShowComplexPhoneField =
    isSMSReminderEnabled || isAlwaysShowComplexPhoneFieldEnabled;

  const phoneFieldType = shouldShowComplexPhoneField
    ? FieldType.PHONE_COUNTRY_CODE
    : FieldType.PHONE;

  const numberOfParticipantsDefaultValue = 1;

  const nameField = form.firstName;

  const fields = [
    ...(shouldShowContactFields
      ? [
          createNameField(),
          ...(form.lastName ? [createLastNameField()] : []),
          createEmailField(),
          createPhoneField(),
        ]
      : []),
    ...(isSMSReminderEnabled ? [createSMSReminderField()] : []),
    ...(maxNumberOfParticipants! > 1 && !isDynamicPrice
      ? [createNumberOfParticipantsField()]
      : []),
    ...(shouldShowAddressField(slotService?.nestedSlot?.location) &&
    form.address
      ? [createAddressField(form.address!, preFilledValues)]
      : []),
    ...mapCustomFields(),
  ];

  const isPhoneRequired = form?.phone?.userConstraints?.required;
  const rule: V2Rule[] = [
    {
      enabled: isSMSReminderEnabled && !isPhoneRequired,
      condition: {
        eq: { fieldKey: ReservedFieldIds.SMS_REMINDER, value: true },
      },
      actions: [
        { fieldKeys: [form.phone!.fieldId!], operation: Operation.REQUIRED },
      ],
    },
  ];

  return {
    formId: form.id,
    fields,
    formViewInfo: {
      rule,
    },
  };

  function mapCustomFields() {
    return customFields.map((field: FormField) => {
      const fieldType = getFieldType(field.valueType!);
      const layout =
        fieldType === FieldType.TEXT ? FieldLayout.SHORT : FieldLayout.LONG;
      return createFormField({
        field,
        fieldType,
        layout,
        isFixFormUoUCheckboxLinkEnabled,
        displayProperties: {
          defaultValue: getDefaultValue(preFilledValues, field.fieldId!),
        },
      });
    });
  }

  function createNumberOfParticipantsField() {
    return createFormField({
      field: form.numberOfParticipants!,
      fieldType: FieldType.DROP_DOWN,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
      },
      displayProperties: {
        options: getNumberOfParticipantsOptions(maxNumberOfParticipants!),
        defaultValue: String(
          Math.min(
            Number(
              getDefaultValue(
                preFilledValues,
                form.numberOfParticipants!.fieldId!,
              ),
            ) || numberOfParticipantsDefaultValue,
            maxNumberOfParticipants!,
          ),
        ),
      },
    });
  }

  function createSMSReminderField() {
    return createFormField({
      field: {
        label: t('app.booking-form.fields.sms-reminder.label'),
        fieldId: ReservedFieldIds.SMS_REMINDER,
      },
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.SMS_REMINDER,
      },
      fieldType: FieldType.CHECKBOX,
      displayProperties: {
        defaultValue: getDefaultValue(
          preFilledValues,
          ReservedFieldIds.SMS_REMINDER,
        ),
      },
    });
  }

  function createPhoneField() {
    return createFormField({
      field: form.phone!,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.PHONE,
      },
      fieldType: phoneFieldType,
      layout: shouldShowComplexPhoneField
        ? FieldLayout.LONG
        : FieldLayout.SHORT,
      displayProperties: {
        defaultValue: getDefaultValueForPhoneField({
          preFilledValues,
          fieldId: form.phone?.fieldId!,
          countryCode: countryCode!,
          phoneFieldType,
        }),
      },
    });
  }

  function createEmailField() {
    return createFormField({
      field: form.email!,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.EMAIL,
      },
      fieldType: FieldType.EMAIL,
      layout: FieldLayout.SHORT,
      displayProperties: {
        defaultValue: getDefaultValue(preFilledValues, form.email?.fieldId!),
      },
    });
  }

  function createNameField() {
    return createFormField({
      field: nameField,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.FIRST_NAME,
      },
      fieldType: FieldType.TEXT,
      layout: FieldLayout.SHORT,
      displayProperties: {
        defaultValue: getDefaultValue(preFilledValues, nameField!.fieldId!),
      },
      validations: { maxLength: 100 },
    });
  }

  function createLastNameField() {
    return createFormField({
      field: form.lastName,
      bookingsKeyMapping: {
        key: BookingRequestKeyMappings.LAST_NAME,
      },
      fieldType: FieldType.TEXT,
      layout: FieldLayout.SHORT,
      displayProperties: {
        defaultValue: getDefaultValue(preFilledValues, form.lastName?.fieldId!),
      },
      validations: { maxLength: 100 },
    });
  }
};

const shouldShowAddressField = (location?: BookedLocation) => {
  return location?.locationType === LocationType.CUSTOM;
};

const getNumberOfParticipantsOptions = (maxOption: number): FieldOption[] => {
  return Array.from({ length: maxOption }, (_, key) => ({
    value: `${key + 1}`,
    label: `${key + 1}`,
  }));
};

export const getDefaultValue = (
  preFilledValues: Maybe<FormSubmission>,
  fieldId: string,
): SubmissionValue => {
  return preFilledValues?.[fieldId];
};

export const getDefaultValueForPhoneField = ({
  preFilledValues,
  fieldId,
  countryCode,
  phoneFieldType,
}: {
  preFilledValues: Maybe<FormSubmission>;
  fieldId: string;
  countryCode: string;
  phoneFieldType: FieldType;
}): SubmissionValue => {
  const phoneFromStorage = getDefaultValue(preFilledValues, fieldId);

  if (isComplexPhone(phoneFromStorage)) {
    if (phoneFieldType === FieldType.PHONE_COUNTRY_CODE) {
      return phoneFromStorage;
    }

    return `${phoneFromStorage.prefix}${trimPhoneValue(
      phoneFromStorage.phone,
    )}`;
  }

  if (phoneFieldType === FieldType.PHONE) {
    return phoneFromStorage;
  }

  return { countryCode, phone: '' };
};

const isComplexPhone = (phone: SubmissionValue): phone is ComplexPhone => {
  return (
    typeof phone !== 'string' &&
    (phone as ComplexPhone)?.countryCode &&
    (phone as ComplexPhone)?.phone &&
    (phone as any)?.prefix
  );
};
