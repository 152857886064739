import { DynamicPriceInfo } from '@wix/bookings-uou-types';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';

export const SLOT_NAME = 'additionalTimeSlot';

export declare function OnAddTimeSlot(
  additionalTimeSlotOutput: SelectedAdditionalTimeSlot,
): void;

export type SelectedAdditionalTimeSlot = {
  slotAvailability: SlotAvailability;
  dynamicPrice?: DynamicPriceInfo;
}[];

export type AdditionalTimeSlot$w = {
  selectedSlot: SelectedAdditionalTimeSlot;
  onAddTimeSlot: (onAddTimeSlot: typeof OnAddTimeSlot) => void;
};
