import { LineItem } from '@wix/ambassador-ecom-v1-cart/types';
import { BOOKINGS_APP_DEF_ID } from '@wix/bookings-adapter-ooi-wix-sdk';

export const mapCartToBookingsLineItems = (cartLineItems: LineItem[]) => {
  const bookingsLineItems =
    cartLineItems?.filter(
      (lineItem: LineItem) =>
        lineItem.catalogReference?.appId === BOOKINGS_APP_DEF_ID,
    ) || [];
  return bookingsLineItems;
};
