import {
  SANTA_MEMBERS_APP_ID,
  MEMBERS_AREA_SECTION_ID,
  BOOKINGS_APP_DEF_ID,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { FormApiContext } from '../utils';

const BOOKINGS_MEMBER_AREA_WIDGET_ID = '14edb332-fdb9-2fe6-0fd1-e6293322b83b';

export const isMembersAreaInstalled = async ({
  flowApi,
}: FormApiContext): Promise<boolean> => {
  try {
    const membersPublicAPI =
      await flowApi.controllerConfig.wixCodeApi.site.getPublicAPI(
        SANTA_MEMBERS_APP_ID,
      );

    return !!(await membersPublicAPI.getSectionUrl({
      widgetId: BOOKINGS_MEMBER_AREA_WIDGET_ID,
      appDefinitionId: BOOKINGS_APP_DEF_ID,
      sectionId: MEMBERS_AREA_SECTION_ID,
    }));
  } catch (e) {
    console.error('Failed to call isMembersAreaInstalled', e);
    return false;
  }
};
