import { ValidationBase, CanBookResult } from './Validations';
import { hasOnlinePaymentMethods } from '../../api/hasOnlinePayments';

export class DayfulBookValidations extends ValidationBase {
  async canBook(): Promise<CanBookResult> {
    const isPremium = await this.isPremium();

    return {
      canBook: isPremium || (this.isOffline() && this.hasNoPricingPlans()),
      reason: {
        premiumError: !isPremium,
      },
    };
  }

  protected applicableForPayments() {
    if (this.isOnline()) {
      const accountId = `${this.wixSdkAdapter.getAppDefId()}:${this.wixSdkAdapter.getInstanceId()}`;
      return hasOnlinePaymentMethods({ accountId });
    } else if (this.isOffline() && !this.hasNoPricingPlans()) {
      return false;
    }
    return true;
  }

  private isPremium() {
    return this.isFeatureEnabled() && this.applicableForPayments();
  }
}
