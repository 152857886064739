import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import { Submission } from '@wix/forms-ui/types';

export function getSessionValues<T = any>(
  wixSdkAdapter: WixOOISDKAdapter,
  key: BookingsQueryParams,
): T | undefined {
  const valueFromStorage = wixSdkAdapter.getFromSessionStorage(key);

  if (valueFromStorage) {
    return JSON.parse(valueFromStorage);
  }

  return undefined;
}

export async function getFieldsValuesInStorage(
  bookingsDataCapsule: BookingsDataCapsule,
  key: BookingsQueryParams,
): Promise<Maybe<Submission>> {
  return bookingsDataCapsule.getItem(key);
}

export async function setFieldsValuesInStorage(
  bookingsDataCapsule: BookingsDataCapsule,
  submission: Submission,
) {
  if (submission) {
    try {
      await bookingsDataCapsule.setItem(
        BookingsQueryParams.FILLED_FIELDS,
        submission,
      );
    } catch (e) {
      console.error('data capsule set value error', e);
    }
  }
}
