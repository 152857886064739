import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { createWixSdkAdapter } from '../../../utils/sdkAdapterFactory';
import { getSessionValues } from '../../../utils/storageFunctions';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { MultiDynamicPriceInfo } from '@wix/bookings-uou-types';

export const getSessionStorageAdapter = ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}) => {
  const wixSdkAdapter = createWixSdkAdapter(
    flowAPI.controllerConfig,
    flowAPI.experiments,
  );

  const SessionStorageAdapter = {
    getServiceId(): string | undefined {
      const serviceId = getSessionValues(
        wixSdkAdapter,
        BookingsQueryParams.SERVICE_ID,
      );
      return serviceId;
    },
    getSlotAvailability(): SlotAvailability | undefined {
      const slotAvailability = getSessionValues(
        wixSdkAdapter,
        BookingsQueryParams.AVAILABILITY_SLOT,
      );
      return slotAvailability;
    },
    getTimezone(): string | undefined {
      const timezone = getSessionValues(
        wixSdkAdapter,
        BookingsQueryParams.TIMEZONE,
      );
      return timezone;
    },
    getDynamicPricePreSelection(
      serviceId: string,
      startDate: string,
    ): MultiDynamicPriceInfo | undefined {
      const dynamicPriceInfoStorage: MultiDynamicPriceInfo | undefined =
        getSessionValues<MultiDynamicPriceInfo>(
          wixSdkAdapter,
          BookingsQueryParams.DYNAMIC_PRICING,
        );

      if (dynamicPriceInfoStorage && !Array.isArray(dynamicPriceInfoStorage)) {
        return [
          {
            serviceId,
            startDate,
            dynamicPriceInfo: dynamicPriceInfoStorage,
          },
        ];
      }

      return dynamicPriceInfoStorage;
    },
  };

  return SessionStorageAdapter;
};
