import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { FormState, ServiceData, SlotService } from '../state/types';
import { widgetDefaults } from './consts';
import { VisitorLogger } from '@wix/yoshi-flow-editor';
import { Location as ServiceLocation } from '../mappers/service.mapper';
import { getServiceSlotIdentifier, mapToArray } from '../index';
import { CustomOption, DynamicPriceInfo } from '../../types/dynamicPrice';
import { ServiceOptionType } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/build/cjs/types.impl';
import { SelectedVariants } from '@wix/bookings-uou-types';
import { GUID } from '@wix/bi-logger-wixboost-users/v2/types';

export interface FormBiLogger extends VisitorLogger {
  update: (state: FormState) => void;
  initialDefaultBiLogger: (state: FormState) => void;
}

const getSelectedLocation = (location: ServiceLocation) => {
  return location?.id || location?.name || location?.address;
};

export const getDynamicPriceBIReportParams = (
  dynamicPriceInfo: DynamicPriceInfo,
  shouldStringify = false,
): {
  price_type?: ServiceOptionType;
  numOfOptions?: number;
  dynamic_price_participants_json?: any | string;
} => {
  const dynamicPriceType = dynamicPriceInfo?.selectedPreferences?.[0].type;
  const isCustomType = dynamicPriceType === ServiceOptionType.CUSTOM;
  const dynamic_price_participants = isCustomType
    ? dynamicPriceInfo.selectedVariants?.map(
        (selectedVariant: SelectedVariants) => {
          const option = dynamicPriceInfo?.customOptions?.find(
            (customOption: CustomOption) =>
              customOption.optionId === selectedVariant.choices?.[0]?.optionId,
          )?.options?.[0];
          return (
            option && {
              label: option?.title,
              amount: option?.price,
              num_participants: selectedVariant.numberOfParticipants,
            }
          );
        },
      )
    : [];
  const dynamic_price_participants_json = shouldStringify
    ? JSON.stringify(dynamic_price_participants)
    : dynamic_price_participants;
  return {
    price_type: dynamicPriceType,
    numOfOptions:
      dynamicPriceInfo?.serviceOptionsAndVariants?.options?.values?.length,
    dynamic_price_participants_json,
  };
};

export const extraInfoMapper = (
  serviceData: ServiceData,
  numberOfParticipants: number,
  bookingIds?: { [key: string]: string } | undefined,
  withDynamicPrice?: boolean,
) =>
  JSON.stringify(
    mapToArray<SlotService>(serviceData.slotServices).map((slotService) => {
      let dynamicPriceBIReportParams;
      let bookingId: GUID | undefined = '';
      if (bookingIds) {
        bookingId =
          bookingIds && Object.keys(bookingIds)?.length
            ? (bookingIds[getServiceSlotIdentifier(slotService.nestedSlot)] as
                | GUID
                | undefined)
            : undefined;
      }

      if (
        slotService.dynamicPriceInfo?.serviceOptionsAndVariants &&
        withDynamicPrice
      ) {
        dynamicPriceBIReportParams = getDynamicPriceBIReportParams(
          slotService.dynamicPriceInfo!,
        );
      }
      return {
        staffId: slotService.service.staffMembers
          .map((staffMember) => staffMember.id)
          .join(', '),
        sessionId: slotService.nestedSlot.sessionId,
        startTime: slotService.nestedSlot.startDate,
        currency: slotService.service.payment.paymentDetails.currency,
        price: slotService.service.payment.paymentDetails.price,
        depositAmount: slotService.service.payment.paymentDetails.minCharge,
        paymentDetails: slotService.selectedPaymentOption.id,
        servicePaymentOptions: slotService.service.paymentTypes,
        selectedPaymentType: slotService.selectedPaymentType,
        selectedLocation: getSelectedLocation(slotService.service.location),
        serviceId: slotService.service.id,
        numParticipants: numberOfParticipants,
        serviceType: slotService.service.type,
        ...(dynamicPriceBIReportParams ?? {}),
        ...(bookingId ? { bookingId } : {}),
      };
    }),
  );

export function createFormBiLogger({
  viewerBiLogger,
  wixSdkAdapter,
}: {
  viewerBiLogger: VisitorLogger;
  wixSdkAdapter: any;
}): FormBiLogger {
  const mapStateToDefaultBiParams = (state: FormState) => {
    const {
      formInputs: { numberOfParticipants },
      businessInfo,
      couponInfo,
      memberDetails,
      serviceData,
    } = state;
    const { formSchema } = serviceData;
    const numberOfFields = formSchema.fields?.length;
    const numberOfRequiredFields = formSchema.fields?.reduce(
      (requiredFieldSum, field) =>
        requiredFieldSum +
        (field.renderInfo?.validationProperties?.required ? 1 : 0),
      0,
    );

    const firstSlotService = mapToArray<SlotService>(
      serviceData.slotServices,
    )[0];

    return {
      pageName: widgetDefaults.pageName,
      extraInfo: extraInfoMapper(serviceData, numberOfParticipants),
      staffId: firstSlotService.service.staffMembers
        .map((staffMember) => staffMember.id)
        .join(', '),
      sessionId: firstSlotService.nestedSlot.sessionId,
      startTime: firstSlotService.nestedSlot.startDate,
      currency: firstSlotService.service.payment.paymentDetails.currency,
      price: firstSlotService.service.payment.paymentDetails.price,
      depositAmount: firstSlotService.service.payment.paymentDetails.minCharge,
      paymentDetails: firstSlotService.selectedPaymentOption.id,
      servicePaymentOptions: JSON.stringify(
        firstSlotService.service.paymentTypes,
      ),
      selectedPaymentType: firstSlotService.selectedPaymentType,
      selectedLocation: getSelectedLocation(firstSlotService.service.location),
      serviceId: firstSlotService.service.id,
      numParticipants: numberOfParticipants,
      serviceType: firstSlotService.service.type,
      formId: formSchema.formId! as any,
      language: businessInfo?.language,
      isCouponCode: !!couponInfo.appliedCoupon?.couponDiscount,
      couponId: couponInfo.appliedCoupon?.couponId,
      smsCapability: businessInfo.isSMSReminderEnabled,
      isUoUloggedIn: !!memberDetails,
      contactId: memberDetails?.contactId,
      memberId: memberDetails?.id,
      numberOfRequiredFields,
      numberOfFields,
    };
  };

  const updateDefaultBiParams = (state: FormState) => {
    viewerBiLogger.updateDefaults(mapStateToDefaultBiParams(state));
  };

  const initialDefaultBiLogger = (state: FormState) => {
    viewerBiLogger?.updateDefaults?.({
      ...widgetDefaults,
      referralInfo: wixSdkAdapter.getUrlQueryParamValue(
        BookingsQueryParams.REFERRAL,
      ),
      ...mapStateToDefaultBiParams(state),
    });
  };

  return Object.assign(viewerBiLogger, {
    update: updateDefaultBiParams,
    initialDefaultBiLogger,
  }) as FormBiLogger;
}
