import {
  FormPageData,
  IncompleteFormNestedSlot,
  IncompleteFormSelectedSlot,
  PageAPIData,
} from '../../types/types';
import { generateLineItemId } from '../../api/implementations/utils';
import type { Experiments } from '@wix/yoshi-flow-editor';
import { DynamicPriceInfo, SelectedVariants } from '@wix/bookings-uou-types';
import { getServiceSlotIdentifier } from '..';
import { ExperimentsConsts } from '../../consts/experiments';
import { Booking } from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';

export type MapBookingsToFormSelectedSlot = {
  bookings: Booking[];
  openSpots: number;
  totalSpots: number;
  timezone: string;
  experiments: Experiments;
};

export const mapBookingsToFormSelectedSlot = ({
  bookings,
  openSpots,
  totalSpots,
  timezone,
  experiments,
}: MapBookingsToFormSelectedSlot): FormPageData => {
  const formSelectedSlot: IncompleteFormSelectedSlot = {
    timezone,
    openSpots,
    totalSpots,
    nestedSlots: generateNestedSlots(bookings),
  };

  const dynamicPricePreSelection = generateDynamicPriceSelection(
    bookings,
    formSelectedSlot,
    experiments,
  );

  return {
    timezone,
    formSelectedSlot,
    dynamicPricePreSelection,
  };
};

export const generateNestedSlots = (
  bookings: Booking[],
): IncompleteFormNestedSlot[] => {
  return bookings.map((booking): IncompleteFormNestedSlot => {
    const slot = booking?.bookedEntity?.slot || {};

    return {
      lineItemId: generateLineItemId(),
      v2Availability: booking?.v2Availability,
      groupId: booking?.groupId,
      startDate: slot.startDate!,
      endDate: slot.endDate!,
      serviceId: slot.serviceId!,
      resource: slot.resource,
      sessionId: slot?.sessionId,
      scheduleId: slot.scheduleId,
      timezone: slot.timezone!,
      location: slot?.location,
    };
  });
};

export const generateDynamicPriceSelection = (
  bookings: Booking[],
  formSelectedSlot: IncompleteFormSelectedSlot,
  experiments: Experiments,
) => {
  const dynamicPricePreSelection: { [key: string]: DynamicPriceInfo } = {};
  const isMultiServiceAppointmentEnabled = experiments.enabled(
    ExperimentsConsts.MultiServiceAppointment,
  );
  const isFormPaymentOptionNewDropdownEnabled = experiments.enabled(
    ExperimentsConsts.FormPaymentOptionNewDropdown,
  );

  bookings?.reduce((acc, curr) => {
    if (curr?.participantsChoices?.serviceChoices) {
      const relevantSlot = formSelectedSlot.nestedSlots.find((nestedSlot) => {
        if (nestedSlot.startDate && nestedSlot.serviceId) {
          return (
            nestedSlot.startDate === curr?.bookedEntity?.slot?.startDate &&
            nestedSlot.serviceId === curr?.bookedEntity?.slot?.serviceId
          );
        }
        return false;
      });

      if (relevantSlot) {
        const selectedVariants: SelectedVariants[] =
          curr.participantsChoices.serviceChoices.map(
            (serviceChoice): SelectedVariants => ({
              numberOfParticipants: serviceChoice.numberOfParticipants || 1,
              choices: serviceChoice.choices,
            }),
          );

        acc[getServiceSlotIdentifier(relevantSlot)] = {
          selectedVariants,
          nonEditable:
            isMultiServiceAppointmentEnabled ||
            isFormPaymentOptionNewDropdownEnabled,
        };
      }
    }
    return acc;
  }, dynamicPricePreSelection);

  return dynamicPricePreSelection;
};

// TODO: New Navigation: Use a formApi "setBookings" to Create Bookings and update the url with bookings ids, instead mapping it from pageData like now
export const mapPageDataToBookings = (
  pageData: PageAPIData,
): Booking[] | undefined => {
  let bookings: Booking[] | undefined;

  if (pageData?.slotAvailability?.nestedSlots?.length) {
    bookings = pageData.slotAvailability.nestedSlots.map((slot): Booking => {
      const serviceChoices = pageData?.dynamicPricePreSelection?.find(
        (dynamicPrice) =>
          dynamicPrice.serviceId === slot.serviceId &&
          dynamicPrice.startDate === slot.start,
      )?.dynamicPriceInfo?.selectedVariants;
      return {
        v2Availability: pageData?.slotAvailability?.isFromV2,
        bookedEntity: {
          slot: {
            startDate: slot.start,
            endDate: slot.end,
            serviceId: slot.serviceId,
            scheduleId: slot.scheduleId,
            resource: slot.resource,
            sessionId: pageData?.slotAvailability?.slot?.sessionId,
            timezone: pageData.timezone,
            location: pageData?.slotAvailability?.slot?.location,
          },
        },
        participantsChoices: {
          serviceChoices,
        },
      };
    });
  } else {
    const serviceChoices = pageData?.dynamicPricePreSelection?.find(
      (dynamicPrice) =>
        dynamicPrice.serviceId ===
          pageData?.slotAvailability?.slot?.serviceId &&
        dynamicPrice.startDate === pageData?.slotAvailability?.slot?.startDate,
    )?.dynamicPriceInfo?.selectedVariants;

    bookings = [
      {
        v2Availability: pageData?.slotAvailability?.isFromV2,
        bookedEntity: {
          slot: {
            startDate: pageData.slotAvailability?.slot?.startDate!,
            endDate: pageData.slotAvailability?.slot?.endDate!,
            serviceId:
              pageData.slotAvailability?.slot?.serviceId! || pageData.serviceId,
            resource: pageData.slotAvailability?.slot?.resource,
            sessionId: pageData.slotAvailability?.slot?.sessionId,
            scheduleId: pageData.slotAvailability?.slot?.scheduleId,
            timezone: pageData.timezone,
            location: pageData.slotAvailability?.slot?.location,
          },
        },
        participantsChoices: {
          serviceChoices,
        },
      },
    ];
  }

  return bookings;
};
