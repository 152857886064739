import {
  BusinessInfo,
  PaymentOption,
  TFunction,
} from '../../../../types/types';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Service } from '../../../mappers/service.mapper';
import { ListEligibleMembershipsResponse } from '@wix/ambassador-memberships-spi-host/http';
import { getPaymentOptions } from '..';

export const getFirstAvailablePaymentOption = ({
  service,
  memberships,
  isPricingPlanInstalled,
  businessInfo,
  numberOfParticipants,
  selectedPaymentOptionId,
  isDynamicPreferenceType,
  t,
  settings,
}: {
  service: Service;
  memberships?: ListEligibleMembershipsResponse;
  isPricingPlanInstalled: boolean;
  businessInfo: BusinessInfo;
  numberOfParticipants: number;
  selectedPaymentOptionId: string;
  isDynamicPreferenceType?: boolean;
  t: TFunction;
  settings: ControllerFlowAPI['settings'];
}): PaymentOption => {
  const dateRegionalSettingsLocale = businessInfo?.dateRegionalSettingsLocale!;
  const dateAndTimeFormatter = new DateTimeFormatter(
    dateRegionalSettingsLocale!,
  );
  const paymentOptions = getPaymentOptions({
    servicePayment: service?.payment,
    memberships,
    isPricingPlanInstalled,
    dateAndTimeFormatter,
    numberOfParticipants,
    dateRegionalSettingsLocale,
    isDynamicPreferenceType,
    t,
    settings,
  });

  const currentSelectedOption = paymentOptions.find(
    (paymentOption) => paymentOption.id === selectedPaymentOptionId,
  );

  if (!currentSelectedOption) {
    throw new Error(
      `Payment Option not found: the id ${selectedPaymentOptionId} was not found in any payment option. this is not usual due to payment options should include at least single session`,
    );
  }

  if (currentSelectedOption.disabled) {
    return paymentOptions.find((paymentOption) => !paymentOption.disabled)!;
  }

  return currentSelectedOption!;
};
