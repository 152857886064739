import { CreateActionParams } from '../actions';

export type OnLogout = () => void;

export function createOnLogoutAction({
  getControllerState,
}: CreateActionParams): OnLogout {
  return async () => {
    const [, setState] = getControllerState();

    setState({
      overrideDefaultFieldsValues: true,
    });
  };
}
