import { CreateActionParams } from '../actions';
import { getFirstAvailablePaymentOption } from '../../../../utils/payment';
import { CreateBookingErrorType } from '../../../../types/errors';

export type SetNumberOfParticipants = (
  numberOfParticipants: number,
  serviceSlotKey: string,
) => void;

export function createSetNumberOfParticipantsAction({
  getControllerState,
  context,
  internalActions,
}: CreateActionParams): SetNumberOfParticipants {
  return async (numberOfParticipants: number, serviceSlotKey: string) => {
    const [state, setState] = getControllerState();
    const { calculatePaymentDetails, updateServiceSlot, errorHandlers } =
      internalActions;
    const {
      serviceData,
      isPricingPlanInstalled,
      businessInfo,
      formInputs,
      couponInfo,
    } = state;
    const { selectedPaymentOption, service, memberships, dynamicPriceInfo } =
      serviceData.slotServices[serviceSlotKey];
    const { t, settings } = context;
    setState({
      formInputs: {
        ...formInputs,
        numberOfParticipants,
      },
    });
    errorHandlers.removeErrors([
      CreateBookingErrorType.SESSION_CAPACITY_EXCEEDED,
      CreateBookingErrorType.SCHEDULE_CAPACITY_EXCEEDED,
    ]);

    await calculatePaymentDetails({
      couponCode: couponInfo.appliedCoupon?.couponCode,
    });

    const isDynamicPreferenceType = dynamicPriceInfo?.isDynamicPreferenceType!;
    const firstAvailableOption = getFirstAvailablePaymentOption({
      service,
      memberships,
      isPricingPlanInstalled,
      businessInfo,
      numberOfParticipants,
      selectedPaymentOptionId: selectedPaymentOption.id,
      isDynamicPreferenceType,
      t,
      settings,
    });
    if (firstAvailableOption?.id) {
      updateServiceSlot(
        { selectedPaymentOption: firstAvailableOption },
        serviceSlotKey,
      );
    }
  };
}
