import { FormState, SlotServices } from '../../../../utils/state/types';
import { SubmissionResponse } from '@wix/forms-ui/types';
import { CartFlow, FlowType } from '../../../../types/types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { setFieldsValuesInStorage } from '../../../../utils/storageFunctions';
import { CreateActionParams } from '../actions';
import { FormStatus } from '../../../../types/form-state';
import { getContactDetailsWithFieldIds } from '../../../../utils/mappers/collapseForm.mapper';
import handleChoosePlanFlow from './handleChoosePlanFlow';
import { handleOwnerFlow } from './handleOwnerFlow';
import { handlePreviewFlow } from './handlePreviewFlow';
import { handleTemplateFlow } from './handleTemplateFlow';
import { handleSingleSessionFlow } from './handleSingleSessionFlow';

export type OnSubmit = ({
  submissionResponse,
  flowType,
  cartFlow,
}: {
  submissionResponse: SubmissionResponse;
  flowType?: FlowType;
  cartFlow?: CartFlow;
}) => Promise<void>;

export function createOnSubmitAction(
  actionFactoryParams: CreateActionParams,
): OnSubmit {
  return async ({
    submissionResponse,
    flowType = FlowType.SINGLE_SESSION,
    cartFlow,
  }) => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { wixSdkAdapter, bookingsDataCapsule } = actionFactoryParams.context;
    const { status, serviceData, formSelectedSlot } = state;

    if (status === FormStatus.IDLE) {
      if (submissionResponse.state.valid) {
        setState({
          status: FormStatus.PROCESSING_SUBMIT_ACTION,
        });

        const submission = getSubmission(state, submissionResponse);

        await setFieldsValuesInStorage(bookingsDataCapsule, submission);

        if (flowType === FlowType.SINGLE_SESSION) {
          flowType = getBookFlowType(
            state,
            serviceData.slotServices,
            wixSdkAdapter,
          );
        }

        switch (flowType) {
          case FlowType.CHOOSE_PLAN:
            return handleChoosePlanFlow(
              actionFactoryParams,
              serviceData,
              formSelectedSlot,
            );
          case FlowType.SINGLE_SESSION:
            return handleSingleSessionFlow(
              actionFactoryParams,
              submission,
              cartFlow,
            );
          case FlowType.OWNER:
            return handleOwnerFlow(actionFactoryParams);
          case FlowType.PREVIEW:
            return handlePreviewFlow(actionFactoryParams);
          case FlowType.TEMPLATE:
            return handleTemplateFlow(actionFactoryParams);
        }
      }
    }
  };
}

function getBookFlowType(
  state: FormState,
  slotServices: SlotServices,
  wixSdkAdapter: WixOOISDKAdapter,
): FlowType {
  if (wixSdkAdapter.isOwner() && !wixSdkAdapter.isPreviewMode()) {
    return FlowType.OWNER;
  } else if (wixSdkAdapter.isPreviewMode()) {
    return FlowType.PREVIEW;
  } else if (wixSdkAdapter.isTemplateMode()) {
    return FlowType.TEMPLATE;
  } else {
    return FlowType.SINGLE_SESSION;
  }
}

function getSubmission(
  state: FormState,
  submissionResponse: SubmissionResponse,
) {
  const { isServiceInCart, serviceData, collapseFormValues } = state;
  if (isServiceInCart) {
    return {
      ...submissionResponse.submission,
      ...getContactDetailsWithFieldIds({
        form: serviceData.form,
        contactDetails: collapseFormValues,
      }),
    };
  }

  return submissionResponse.submission;
}
