import { ControllerFlowAPI, IUser, ReportError } from '@wix/yoshi-flow-editor';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import { FormApi } from '../../api/FormApi';
import { TFunction } from '../../types/types';
import { FormBiLogger } from '../bi/biLoggerFactory';

export type FormContext = {
  t: TFunction;
  settings: ControllerFlowAPI['settings'];
  experiments: ControllerFlowAPI['experiments'];
  wixSdkAdapter: WixOOISDKAdapter;
  bookingsDataCapsule: BookingsDataCapsule;
  formApi: FormApi;
  biLogger?: FormBiLogger;
  reportError: ReportError;
  user: IUser;
};

export const createFormContext = ({
  t,
  flowApi,
  wixSdkAdapter,
  bookingsDataCapsule,
  formApi,
  biLogger,
  reportError,
  user,
}: {
  t: TFunction;
  flowApi: ControllerFlowAPI;
  wixSdkAdapter: WixOOISDKAdapter;
  bookingsDataCapsule: BookingsDataCapsule;
  formApi: FormApi;
  biLogger?: FormBiLogger;
  reportError: ReportError;
  user: IUser;
}): FormContext => {
  return {
    t,
    settings: flowApi.settings,
    experiments: flowApi.experiments,
    wixSdkAdapter,
    bookingsDataCapsule,
    formApi,
    biLogger,
    reportError,
    user,
  };
};
