import { BenefitWithPlanInfo } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { isServiceOfferedAsPricingPlan } from '@wix/bookings-uou-mappers';
import { PaymentOption, TFunction } from '../../../../types/types';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { SlotService } from '../../../state/types';
import {
  createBuyPlanOption,
  createPayOfflineOption,
  createPayOnlineOption,
  createUsePlanOption,
  isOfferedAsOneTime,
} from '..';
import { SelectedPaymentOption } from '../../../../types/ambassador/bookings/ambassador-bookings-v2-booking';

export const getSlotServicePaymentOptions = ({
  slotService,
  benefitsWithPlanInfo,
  numberOfParticipants,
  isPricingPlanInstalled,
  t,
  dateAndTimeFormatter,
}: {
  slotService: SlotService;
  benefitsWithPlanInfo?: BenefitWithPlanInfo[];
  numberOfParticipants: number;
  isPricingPlanInstalled: boolean;
  t: TFunction;
  dateAndTimeFormatter: DateTimeFormatter;
}): PaymentOption[] => {
  const options: PaymentOption[] = slotService.memberships?.eligibleMemberships
    ?.length
    ? slotService.memberships.eligibleMemberships!.map((membership) => {
        return createUsePlanOption(
          membership,
          numberOfParticipants,
          benefitsWithPlanInfo,
          t,
          dateAndTimeFormatter,
        );
      })
    : [];

  const showBuyAPricingPlan = isServiceOfferedAsPricingPlan(
    slotService.service.payment,
    isPricingPlanInstalled,
  );

  if (options.length === 0 && showBuyAPricingPlan) {
    slotService.possiblePlans?.forEach((plan) => {
      options.push(createBuyPlanOption(plan));
    });
  }
  const showPaySingleSession = isOfferedAsOneTime(slotService.service.payment);

  if (showPaySingleSession) {
    if (
      slotService.service.paymentTypes.includes(SelectedPaymentOption.ONLINE)
    ) {
      options.push(createPayOnlineOption());
    }
    if (
      slotService.service.paymentTypes.includes(SelectedPaymentOption.OFFLINE)
    ) {
      options.push(createPayOfflineOption());
    }
  }

  return options;
};
