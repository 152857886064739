import {
  CalculatePaymentDetails,
  createCalculatePaymentDetailsAction,
} from './calculatePaymentDetails/calculatePaymentDetails';
import {
  createSetNumberOfParticipantsAction,
  SetNumberOfParticipants,
} from './setNumberOfParticipants/setNumberOfParticipants';
import { FormState } from '../../../utils/state/types';
import { FormContext } from '../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../utils/ControlledComponent/ControlledComponent.types';
import { createOnSubmitAction, OnSubmit } from './onSubmit/onSubmit';
import {
  createUpdateServiceSlotAction,
  UpdateServiceSlot,
} from './updateServiceSlot/updateServiceSlot';
import {
  createSetSelectedPaymentTypeAction,
  setSelectedPaymentType,
} from './setSelectedPaymentType/setSelectedPaymentType';
import {
  createPromptLoginAction,
  PromptLogin,
} from './promptLogin/promptLogin';
import { createSetCouponAction, SetCoupon } from './setCoupon/setCoupon';
import {
  createRemoveCouponAction,
  RemoveCoupon,
} from './removeCoupon/removeCoupon';
import {
  createOnToggleCouponAction,
  OnToggleCoupon,
} from './onToggleCoupon/onToggleCoupon';
import { createOnLoginAction, OnLogin } from './onLogin/onLogin';
import {
  OnToastClose,
  createOnToastCloseAction,
} from './onToastClose/onToastClose';
import {
  OnDialogClose,
  createOnDialogCloseAction,
} from './onDialogClose/onDialogClose';
import {
  createInitializeWidgetAction,
  InitializeWidget,
} from './initializeWidget/initializeWidget';
import { createOnLogoutAction, OnLogout } from './onLogout/onLogout';
import {
  createFormErrorHandlers,
  ErrorHandlers,
} from './errorHandlers/errorHandlers';
import { createSetEmailAction, SetEmail } from './setEmail/setEmail';
import {
  OnSelectedVariants,
  createOnSelectedVariantsAction,
} from './onSelectedVariants/onSelectedVariants';
import {
  createOnCartCTAClickAction,
  OnCartCTAClick,
} from './onCartCTAClick/onCartCTAClick';
import {
  createOnBackButtonClickAction,
  OnBackButtonClick,
} from './onBackButtonClick/onBackButtonClick';
import { AddError } from './errorHandlers/addError/addError';
import { RemoveErrors } from './errorHandlers/removeError/removeErrors';
import { ClearErrorByTypes } from './errorHandlers/clearErrorByTypes/clearErrorByTypes';
import {
  createSetServiceSelectedPaymentOption,
  setServiceSelectedPaymentOptionType,
} from './setServiceSelectedPaymentOption/setServiceSelectedPaymentOption';
import {
  createSetDefaultPaymentOptionsAction,
  SetDefaultPaymentOptions,
} from './setDefaultPaymentOptions/setDefaultPaymentOptions';
import {
  OnConflictAlertShown,
  createOnConflictAlertShownAction,
} from './onConflictAlertShown/onConflictAlertShown';

export type InternalFormActions = {
  errorHandlers: ErrorHandlers;
  calculatePaymentDetails: CalculatePaymentDetails;
  updateServiceSlot: UpdateServiceSlot;
};
export interface CreateActionParams
  extends ActionFactoryParams<FormState, FormContext> {
  internalActions: InternalFormActions;
}
export type FormControllerActions = {
  onSubmit: OnSubmit;
  onCartCTAClick: OnCartCTAClick;
  onBackButtonClick: OnBackButtonClick;
  setCoupon: SetCoupon;
  removeCoupon: RemoveCoupon;
  updateServiceSlot: UpdateServiceSlot;
  onLogin: OnLogin;
  promptLogin: PromptLogin;
  setNumberOfParticipants: SetNumberOfParticipants;
  setEmail: SetEmail;
  onToggleCoupon: OnToggleCoupon;
  onToastClose: OnToastClose;
  onDialogClose: OnDialogClose;
  initializeWidget: InitializeWidget;
  onLogout: OnLogout;
  setSelectedPaymentType: setSelectedPaymentType;
  onSelectedVariants: OnSelectedVariants;
  addError: AddError;
  removeErrors: RemoveErrors;
  clearErrorByTypes: ClearErrorByTypes;
  setServiceSelectedPaymentOption: setServiceSelectedPaymentOptionType;
  setDefaultPaymentOptions: SetDefaultPaymentOptions;
  onConflictAlertShown: OnConflictAlertShown;
};
export const createFormActions = (
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
): FormControllerActions => {
  const updateServiceSlot = createUpdateServiceSlotAction(actionFactoryParams);
  const errorHandlers = createFormErrorHandlers(actionFactoryParams);
  const calculatePaymentDetails = createCalculatePaymentDetailsAction({
    actionFactoryParams,
    errorHandlers,
  });
  const internalActions: InternalFormActions = {
    errorHandlers,
    calculatePaymentDetails,
    updateServiceSlot,
  };
  const actionParams = { ...actionFactoryParams, internalActions };

  return {
    onSubmit: createOnSubmitAction(actionParams),
    updateServiceSlot: createUpdateServiceSlotAction(actionParams),
    promptLogin: createPromptLoginAction(actionParams),
    onCartCTAClick: createOnCartCTAClickAction(actionParams),
    onBackButtonClick: createOnBackButtonClickAction(actionParams),
    onLogin: createOnLoginAction(actionParams),
    setCoupon: createSetCouponAction(actionParams),
    removeCoupon: createRemoveCouponAction(actionParams),
    setNumberOfParticipants: createSetNumberOfParticipantsAction(actionParams),
    setEmail: createSetEmailAction(actionParams),
    onToggleCoupon: createOnToggleCouponAction(actionParams),
    onToastClose: createOnToastCloseAction(actionParams),
    onDialogClose: createOnDialogCloseAction(actionParams),
    initializeWidget: createInitializeWidgetAction(actionParams),
    onLogout: createOnLogoutAction(actionParams),
    setSelectedPaymentType: createSetSelectedPaymentTypeAction(actionParams),
    onSelectedVariants: createOnSelectedVariantsAction(actionParams),
    setServiceSelectedPaymentOption:
      createSetServiceSelectedPaymentOption(actionParams),
    setDefaultPaymentOptions:
      createSetDefaultPaymentOptionsAction(actionParams),
    ...errorHandlers,
    onConflictAlertShown: createOnConflictAlertShownAction(actionParams),
  };
};
