import { SelectedPaymentOption } from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { PaymentOptionType } from '@wix/ambassador-totals-calculator/http';

export const getPaymentOption = (
  selectedPaymentType: SelectedPaymentOption,
  deposit: Maybe<number>,
) => {
  if (deposit) {
    return PaymentOptionType.DEPOSIT_ONLINE;
  } else {
    return selectedPaymentType === SelectedPaymentOption.ONLINE
      ? PaymentOptionType.FULL_PAYMENT_ONLINE
      : PaymentOptionType.FULL_PAYMENT_OFFLINE;
  }
};
