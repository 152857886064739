import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getURLAdapter } from './URLAdapter';
import { getInputServiceType } from './utils';

export const isInputValid = ({ flowAPI }: { flowAPI: ControllerFlowAPI }) => {
  const urlAdapter = getURLAdapter({ flowAPI });
  const timezone = urlAdapter.getTimezone();

  if (!timezone) {
    console.error('URL data validation failed for timezone');
    return false;
  }

  const serviceType = getInputServiceType({ flowAPI });

  if (!serviceType) {
    console.error(
      'URL data validation failed, service type could not be resolved',
    );
    return false;
  }

  return true;
};
