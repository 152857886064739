import {
  GetActiveFeaturesResponse,
  GetBusinessResponse,
  NotificationType,
} from '@wix/ambassador-services-catalog-server/http';
import { mapResponseToBusinessInfo } from '@wix/bookings-uou-mappers';
import { ActiveFeatures } from '@wix/bookings-uou-types';
import { BusinessInfo } from '../../types/types';

export const mapBusinessResponseToBusinessInfo = (
  businessResponse: GetBusinessResponse,
): BusinessInfo => {
  const businessInfoBase = mapResponseToBusinessInfo(businessResponse);
  return {
    ...businessInfoBase,
    isSMSReminderEnabled: isSMSReminderEnabled(businessResponse),
    activeFeatures: mapActiveFeatures(businessResponse.activeFeatures),
  };
};

const isSMSReminderEnabled = (
  businessResponse: GetBusinessResponse,
): boolean => {
  const applicableForSmsReminders =
    !!businessResponse.activeFeatures?.applicableForSmsReminders;
  const isUserEnabledSMSReminder =
    !!businessResponse?.notificationsSetup?.notifications?.find(
      (notification) =>
        notification.isEnabled &&
        notification.type === NotificationType.REMINDER_SMS &&
        notification.requireParticipantConsent,
    );
  return applicableForSmsReminders && isUserEnabledSMSReminder;
};

const mapActiveFeatures = (
  activeFeatures?: GetActiveFeaturesResponse,
): ActiveFeatures => {
  return {
    payments: !!activeFeatures?.applicableForPayments,
    groups: !!activeFeatures?.applicableForGroups,
    reminders: !!activeFeatures?.applicableForReminders,
    smsReminders: !!activeFeatures?.applicableForSmsReminders,
    staffMembers: true,
    course: !!activeFeatures?.applicableForCourse,
    individual: !!activeFeatures?.applicableForIndividual,
    staffLimit: activeFeatures?.bookingsStaffLimit!,
  };
};
