import {
  ContactDetails,
  CustomFormField,
} from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { mapToArray } from '../../../utils';
import { ServiceData, SlotService } from '../../../utils/state/types';
import { FormApiContext } from '../utils';
import { sendCreateGroupBookingsRequest } from './sendCreateGroupBookingsRequest';
import { sendCreateBookingsRequest } from './sendCreateBookingsRequest';

export type CreateBookingsArgs = {
  serviceData: ServiceData;
  contactDetails: ContactDetails;
  additionalFields: CustomFormField[];
  numberOfParticipants: number | undefined;
  sendSmsReminder: boolean | undefined;
};

export const createBookings = async ({
  httpClient,
  serviceData,
  contactDetails,
  additionalFields,
  numberOfParticipants,
  sendSmsReminder,
}: CreateBookingsArgs & FormApiContext): Promise<{
  [key: string]: string;
}> => {
  if (serviceData.isSingleService) {
    const bookingIds = await sendCreateBookingsRequest(
      httpClient,
      mapToArray<SlotService>(serviceData.slotServices),
      contactDetails,
      additionalFields,
      numberOfParticipants,
      sendSmsReminder,
    );
    return bookingIds;
  } else {
    const createGroupBookingsResponse = await sendCreateGroupBookingsRequest(
      httpClient,
      mapToArray<SlotService>(serviceData.slotServices),
      contactDetails,
      additionalFields,
      numberOfParticipants,
      sendSmsReminder,
    );

    return createGroupBookingsResponse;
  }
};
