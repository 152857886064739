import { PlanWithDescription } from '../../../types/types';
import { FormApiContext } from '../utils';
import type {
  PublicPlan,
  QueryPublicPlansResponse,
} from '@wix/ambassador-pricing-plans-v2-plan/types';
import { queryPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';

export const getPublicPlans = async ({
  httpClient,
  wixSdkAdapter,
}: FormApiContext): Promise<PlanWithDescription[] | undefined> => {
  try {
    const allPlans = (
      await httpClient.request<QueryPublicPlansResponse>(queryPublicPlans({}))
    )?.data;

    return Promise.all(
      (allPlans.plans || []).map(async (plan) => {
        const { price, cycle, duration, freeTrial } =
          await getTranslatedPricingDetails(wixSdkAdapter, plan);
        return {
          ...plan,
          description: [price, cycle, duration, freeTrial]
            .filter((x) => x)
            .join(', '),
        } as PlanWithDescription;
      }),
    );
  } catch (err: any) {
    // Handling a case where pricing plans app is not installed in headless mode
    if (
      err.response.data.message === 'Pricing Plans instanceId not in context'
    ) {
      return undefined;
    }
    throw err;
  }
};

const getTranslatedPricingDetails = async (
  wixSdkAdapter: WixOOISDKAdapter,
  publicPlan: PublicPlan,
) => {
  const pricingPlansApi = await wixSdkAdapter.getPricingPlansApi();
  return pricingPlansApi.getTranslatedPricingDetails(publicPlan);
};
