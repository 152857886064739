import { CreateActionParams } from '../actions';
import { FormStatus } from '../../../../types/form-state';
import { bookingsUouBookingsFormClicks1052 } from '@wix/bi-logger-wixboost-ugc/v2';
import { FormClickAction } from '../../../../types/biLoggerTypes';

export type PromptLogin = () => void;

export function createPromptLoginAction({
  getControllerState,
  context: { wixSdkAdapter, reportError, biLogger },
}: CreateActionParams): PromptLogin {
  return async () => {
    const [, setState] = getControllerState();
    if (!wixSdkAdapter.isPreviewMode()) {
      try {
        setState({
          status: FormStatus.PROCESSING_USER_DETAILS,
        });
        biLogger?.report(
          bookingsUouBookingsFormClicks1052({ action: FormClickAction.Login }),
        );
        await wixSdkAdapter.promptUserLogin();
      } catch (e) {
        setState({
          status: FormStatus.IDLE,
        });
        reportError(e as any);
      }
    }
  };
}
